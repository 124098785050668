import { CloudContentWords } from './words'
import { CloudContentSentences } from './sentences'

// Interface
export interface CloudContent {
  words: CloudContentWords
  sentences: CloudContentSentences
  markdown: any
}

// Words
import enWords from '../../public/content/en/words.json'
import frWords from '../../public/content/fr/words.json'
import esWords from '../../public/content/es/words.json'
import deWords from '../../public/content/de/words.json'

// Sentences
import enSentences from '../../public/content/en/sentences.json'
import frSentences from '../../public/content/fr/sentences.json'
import esSentences from '../../public/content/es/sentences.json'
import deSentences from '../../public/content/de/sentences.json'

// Markdown
import enHomeStory1Content from '../../public/content/en/md/home/story1.md'
import frHomeStory1Content from '../../public/content/fr/md/home/story1.md'
import esHomeStory1Content from '../../public/content/es/md/home/story1.md'
import deHomeStory1Content from '../../public/content/de/md/home/story1.md'

// Main object
export const content = {
  en: {
    words: enWords as CloudContentWords,
    sentences: enSentences,
    markdown: {
      home: {
        story1: enHomeStory1Content
      }
    } as any
  } as any,
  fr: {
    words: frWords as CloudContentWords,
    sentences: frSentences,
    markdown: {
      home: {
        story1: frHomeStory1Content
      }
    } as any
  } as any,
  es: {
    words: esWords as CloudContentWords,
    sentences: esSentences,
    markdown: {
      home: {
        story1: esHomeStory1Content
      }
    } as any
  } as any,
  de: {
    words: deWords as CloudContentWords,
    sentences: deSentences,
    markdown: {
      home: {
        story1: deHomeStory1Content
      }
    } as any
  } as any
  // es: esContent as any,
  // fr: frContent as any,
  // de: deContent as any
}

// import { esContent } from './es/content'
// import { frContent } from './fr/content'
// import { deContent } from './de/content'
