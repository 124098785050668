import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw
} from 'vue-router'
import store from '@/store'
import { Mutations, Actions } from '@/store/enums/StoreEnums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/layout/cantoo/pages/public/Splash.vue'),
    children: [
      {
        path: '/',
        name: 'splash',
        component: () => import('@/views/pages/public/Splash.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layout/cantoo/pages/public/Splash.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/public/errors/Error404.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
      }
    ]
  }
]

const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(),
  routes
})

router.beforeEach((to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  if (!to.params.dontScroll) {
    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  // store.dispatch(Actions.VERIFY_AUTH);
})

export default router
