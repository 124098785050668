// Vue3
import { createApp } from 'vue'
import App from './App.vue'

// Core
import router from '@/router/index'
import store from './store'

// Layout
import ElementPlus from 'element-plus'
import '@/core/plugins/keenthemes'
import '@/core/plugins/prismjs'
import 'bootstrap'

// Internationalization
import i18n from '@/core/plugins/i18n'

// Markdown
import Markdown from 'vue3-markdown-it'

// Select
import vSelect from 'vue-select'

// Component plugins
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'

// Init the app 🎉
async function init() {
  try {
    // Set the API URL
    let baseUrl = process.env.BASE_API_URL || 'http://localhost:8888'

    // Create the app
    const app = createApp(App)

    // Use the modules
    app.use(store)
    app.use(router)
    app.use(ElementPlus)
    app.use(i18n)
    app.use(Markdown)
    app.component('v-select', vSelect)

    // Init the remote components
    initApexCharts(app)
    initInlineSvg(app)
    initVeeValidate()

    // Mount the app 🎉
    app.mount('#app')
  } catch (error) {
    return error
  }
}

init()
