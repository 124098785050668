import { createI18n } from 'vue-i18n/index'

import { content } from '@/content/content'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages: content,
  silentFallbackWarn: true,
  warnHtmlInMessage: 'off'
})

export default i18n
